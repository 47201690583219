<template>
  <div class="">
    <div class="calendar">
      <!-- <vc-calendar></vc-calendar> -->
      <!-- <input v-model="selectedDate" @click="isOpened = true" /> -->
      <vc-date-picker
        v-show="showCalendar"
        ref="calendar"
        :masks="masks"
        :available-dates="tmp"
        :disabled-dates="{ weekdays: disabledDays }"
        mode="date"
        v-model="selectedDate"
        is-required
        :is-inline="true"
      >
        <!-- :popover="popover" -->
        <!-- @popoverWillHide="checkHide"
				@popoverDidShow="setShow"
				@popoverDidHide="setHide" -->
      </vc-date-picker>
      <div class="buttons" id="cal-buttons" v-show="showCalendar">
        <!-- timeofday = '';
						selectedDate = ''; -->
        <button @click="$emit('showCalendar', false)" class="button">
          Anuluj
        </button>
        <button
          @click="$emit('showCalendar', false)"
          :class="[
            'button--blue ',
            timeofday && selectedDate ? '' : 'disabled',
          ]"
        >
          Zapisz
        </button>
      </div>
      <div
        ref="radio"
        id="radio"
        class="services__more"
        :class="showCalendar ? '' : 'hide'"
      >
        <fieldset>
          <div class="radio__wrap calendar__radio">
            <input
              type="radio"
              id="rano"
              name=""
              value="rano"
              v-model="timeofday"
              @click="hideDayTimeRadios = true"
            />
            <label for="rano">
              <span></span>
              <div class="">
                <div class="radio__label">
                  rano
                </div>
              </div>
            </label>
          </div>
          <div class="radio__wrap calendar__radio">
            <input
              type="radio"
              id="popołudnie"
              name=""
              value="popołudnie"
              v-model="timeofday"
              @click="hideDayTimeRadios = true"
            />
            <label for="popołudnie">
              <span></span>
              <div class="">
                <div class="radio__label">
                  popołudnie
                </div>
              </div>
            </label>
          </div>
          <div class="radio__wrap calendar__radio">
            <input
              type="radio"
              id="późne popołudnie"
              name=""
              value="późne popołudnie"
              v-model="timeofday"
              @click="hideDayTimeRadios = true"
            />
            <label for="późne popołudnie">
              <span></span>
              <div class="">
                <div class="radio__label">
                  późne popołudnie
                </div>
              </div>
            </label>
          </div>
        </fieldset>
      </div>
    </div>
    <!-- <p class="calendar__daytime" v-if="timeofday">
      Wybrana pora dnia: <span>{{ timeofday }}</span>
    </p> -->
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "calendar",
  props: ["reset", "showCalendar"],
  data() {
    return {
      popover: {
        keepVisableOnInput: true,
      },
      masks: {
        input: ["DD.MM.YY r."],
        weekdays: "WWW",
      },
      isOpened: false,
      months: [
        "styczeń",
        "luty",
        "marzec",
        "kwiecień",
        "maj",
        "czerwiec",
        "lipiec",
        "sierpień",
        "wrzesień",
        "październik",
        "listopad",
        "grudzień",
      ],
      selectedDate: "",
      timeofday: "",
      tmp: [],
      disabledDays: [],
      showDateTime: true,
      hideDayTimeRadios: false,
    };
  },
  computed: {
    ...mapState(["dates", "saturdayInfo"]),
    ...mapGetters(["getSaturdayInfo"]),
  },
  mounted() {},
  watch: {
    saturdayInfo: function() {
      if (this.saturdayInfo != "nieczynne") {
        this.disabledDays = [1]; // niedziela
      } else {
        this.disabledDays = [1, 7];
      }
    },
    // getSaturdayInfo: function() {},
    getSaturdayInfo: {
      handler(role) {
        if (role) {
          this.checkSaturday();
        }
      },
      immediate: true,
    },
    showCalendar: function(newVal) {
      if (newVal) {
        let cal = document.getElementsByClassName("vc-container")[0];
        cal.appendChild(document.getElementById("radio"));
        cal.appendChild(document.getElementById("cal-buttons"));
      }
    },
    dates: function() {
      delete this.dates[Object.keys(this.dates)[0]]; // usuń dziś
      this.tmp = Object.keys(this.dates)
        .filter((e) => this.dates[e] < 75)
        .map((e) => {
          return { start: e, end: e };
        });
      // console.log(this.dates);
    },
    selectedDate: {
      handler: function() {
        this.timeofday = "";
        var tmp = this.selectedDate;
        var tmpformat = this.formatDate(tmp);
        this.$emit("chooseDate", tmpformat);
        this.showDateTime = true;
        this.hideDayTimeRadios = false;
        // this.$refs.calendar.append(this.$refs.radio);
      },
    },
    timeofday: {
      handler: function() {
        this.$emit("chooseTimeOfDay", this.timeofday);
      },
    },
    reset: {
      handler: function() {
        var that = this;
        this.selectedDate = "";
        this.timeofday = "";
        setTimeout(function() {
          that.showDateTime = false;
        }, 10);
      },
    },
  },
  methods: {
    checkSaturday() {
      //   console.log(this.getSaturdayInfo);

      if (this.getSaturdayInfo != "nieczynne") {
        this.disabledDays = [1]; // niedziela
      } else {
        this.disabledDays = [1, 7];
      }
    },
    checkHide() {
      if (this.timeofday === "") {
        return;
      }
    },
    setHide() {
      this.isOpened = false;
    },
    setShow() {
      this.isOpened = true;
    },
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;

      var tmp = [year, month, day];
      var res = tmp.join("-");
      return res;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/calendar-ie.scss";
.input__item input {
  max-width: 95%;
  @media (max-width: 550px) {
    max-width: 95%;
  }
  @media (max-width: 399px) {
    max-width: 88%;
  }
  // max-width: 397px;
}
.disabled {
  cursor: not-allowed !important;
  background: lightgray 0% 0% no-repeat padding-box !important;
}
.button {
  width: 149px;
  height: 50px;
  background: #c7c7c7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  color: white;
  font: normal normal bold 16px Roboto;
  border: none;
  margin: 0px 10px;
  cursor: pointer;
  &--blue {
    @extend .button;
    background: #3a98d8 0% 0% no-repeat padding-box;
  }
}
.vc-pane {
  margin-bottom: 50px;
  @media (max-width: 430px) {
    margin-bottom: 120px;
  }
}

.vc-text-gray-800 {
  color: #000 !important;
}
.vc-text-base {
  font-size: 14px !important;
}
.vc-text-base::placeholder {
  color: $lightGrey !important;
}
.vc-popover-content-wrapper {
  width: 822px;
  position: absolute !important;
  left: -400px !important;
  top: -350px !important;
}
.vc-popover-content-wrapper.is-interactive {
  position: absolute !important;
  transform: none !important;
  top: -50px !important;
  left: 0px;
  // will-change: transform;
  z-index: 99999 !important;
  padding: 100px;
}

.vc-container {
  margin-left: auto;
  margin-right: auto;
  margin-top: 30px;
  position: absolute !important;
  right: 0;
  top: -350px;
}

.buttons {
  position: absolute !important;
  bottom: 10px;
  display: flex;
  justify-content: center;
  width: 100%;
  // left: 30%;
  // @media (max-width: 665px) {
  // 	left: 0;
  // }
}
fieldset > div:nth-child(3) > label > div > div {
  min-width: 131px;
}
#radio {
  position: absolute !important;
  position: absolute !important;
  bottom: 130px;
  left: 23%;
  @media (max-width: 665px) {
    left: 0;
  }
  @media (max-width: 665px) {
    font-size: 10px !important;
  }
  @media (max-width: 430px) {
    bottom: 190px;
  }
}

.vc-title {
  text-transform: uppercase;
}

.vc-grid-cell {
  @media (max-width: 350px) {
    margin: 3px 6px !important;
  }
  @media (max-width: 665px) {
    margin: 3px 10px;
  }
  margin: 10px 30px;
}

.hide {
  display: none !important;
}

.vc-popover-content::after {
  content: "Zaproponowany termin jest orientacyjny i zostanie potwierdzony telefonicznie przez pracownika serwisu najszybciej jak to możliwe.";
  display: block;
  padding: 0.3em 1em;
  padding-bottom: 0.5em;
  font-size: 12px;
  // width: 822px;
  // margin-right: 300px !important;
  // width: 50%;
  // margin: 0px auto;
}
.calendar {
  // position: relative;
  & span {
    display: flex;
  }
  &__daytime {
    font-size: 12px;
    color: $lightGrey;
    margin-left: 5px;
    margin-top: 1px;
    span {
      color: $lightBlue;
    }
  }
  &__radio {
    font-size: 13px;
  }
}
</style>
