<template>
	<div class="accordion__absolute" v-if="openModal">
		<a class="accordion__close" @click="$emit('openModal', false)">❌</a>
		<div class="">
			<div class="">
				<div class="accordion" v-click-outside="hide">
					<!-- .filter(
							(item) => item.id === dealer_id && item.brand_id === brand_id
						)" -->
					<div
						class="accordion__row"
						v-for="(dealer, i) in dealerList.filter(
							(item) => item.city === city
						)"
						:key="dealer.id"
					>
						<div class="accordion__title" @click="selectRow(i)">
							<h2>{{ dealer.name }}</h2>
							<span>
								<img
									:class="{ arrowActive: selectedRow == i }"
									src="@/assets/images/elem/arrow-dow.png"
									alt=""
								/>
							</span>
						</div>
						<transition name="slide">
							<div class="accordion__hidden" v-if="selectedRow == i">
								<div class="accordion__content">
									<div class="">
										<p class="accordion__text">
											{{ dealer.city }} {{ dealer.address }} <br />
											Godziny otwarcia: <br />
											Pn-Pt: {{ dealer.service_hours }} <br />
											Sb: {{ dealer.service_hours_saturday }}
										</p>
										<div
											class="accordion__button"
											@click="
												setDealer(dealer.id, dealer.name, dealer.brand_id, dealer.service_hours_saturday)
											"
										>
											Wybierz
										</div>
									</div>
									<div class="">
										<div class="accordion__images">
											<div
												class="accordion__logo"
												v-if="
													dealer.id == 12 ||
														dealer.id == 34 ||
														dealer.id == 17 ||
														dealer.id == 1 ||
														dealer.id == 6 ||
														dealer.id == 21 ||
														dealer.id == 24 ||
														dealer.id == 31 ||
														dealer.id == 26 ||
														dealer.id == 29
												"
											>
												<img src="@/assets/images/vw-small.png" alt="" />
											</div>
											<div
												class="accordion__logo"
												v-if="
													dealer.id == 27 ||
														dealer.id == 34 ||
														dealer.id == 2 ||
														dealer.id == 4 ||
														dealer.id == 21 ||
														dealer.id == 24 ||
														dealer.id == 33
												"
											>
												<img src="@/assets/images/audi-small.png" alt="" />
											</div>
											<div
												class="accordion__logo"
												v-if="
													dealer.id == 12 ||
														dealer.id == 15 ||
														dealer.id == 1 ||
														dealer.id == 38 ||
														dealer.id == 21 ||
														dealer.id == 6 ||
														dealer.id == 31 ||
														dealer.id == 39 ||
														dealer.id == 29
														
												"
											>
											<img src="@/assets/images/Skoda_Serwis_vertical_RGB_Electric_Green.png" style="width: 85px;" alt="Skoda serwis" />
											</div>
											<div
												class="accordion__logo"
												v-if="
													dealer.id == 7 || dealer.id == 12 || dealer.id == 1
												"
											>
												<img src="@/assets/images/bentley-small.jpg" alt="" />
											</div>
											<div class="accordion__logo" v-if="dealer.id == 7">
												<img src="@/assets/images/lambo-small.jpg" alt="" />
											</div>
											<div
												class="accordion__logo"
												v-if="
													dealer.id == 10 || dealer.id == 11 || dealer.id == 28
												"
											>
												<img src="@/assets/images/porsche-small.jpg" alt="" />
											</div>
											<!-- <div class="accordion__logo">
                        <img src="@/assets/images/vwu-small.png" alt="">
                      </div> -->
										</div>
									</div>
								</div>
							</div>
						</transition>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import ClickOutside from "vue-click-outside";
	import { mapState } from "vuex";
	export default {
		props: {
			openModal: Boolean,
			dealer_id: String,
			// brand_id: Number,
			city: String,
		},
		data() {
			return {
				selectedRow: null,
				opened: false,
				vcoConfig: {
					events: ["dblclick"],
				},
				activeLogos: [],
			};
		},
		methods: {
			// getDealerName(){
			//   return
			// },
			selectRow: function selectRow(i) {
				this.selectedRow = this.selectedRow === i ? null : i;
			},
			hide() {
				this.$emit("hideModal", false);
			},
			setDealer(id, name, brandid, saturday) {
				// logo ids
				// vw - 115
				// audi - 9
				// skoda - 99
				// bentley - 12
				// lambo - 54
				// porsche - 87
				// vwd - 235
				this.activeLogos = [];
				switch (id) {
					// Audi Centrum Warszawa Okęcie
					case 27:
						this.activeLogos.push(9);
						break;
					// Bentley Warszawa
					case 7:
						this.activeLogos.push(12, 54);
						break;
					// Porsche Centrum Poznań
					case 10:
						this.activeLogos.push(87);
						break;
					// Porsche Centrum Warszawa
					case 11:
						this.activeLogos.push(87);
						break;
					// Porsche Centrum Warszawa Okęcie
					case 28:
						this.activeLogos.push(87);
						break;
					// Porsche Katowice Volkswagen
					case 12:
						this.activeLogos.push(115, 99, 12);
						break;
					// Porsche Kraków Bronowice Skoda
					case 15:
						this.activeLogos.push(99);
						break;
					// Porsche Kraków Volkswagen
					case 34:
						this.activeLogos.push(115, 9);
						break;
					// Porsche Połczyńska Audi
					case 2:
						this.activeLogos.push(115);
						break;
					// Porsche Połczyńska Skoda
					case 38:
						this.activeLogos.push(99);
						break;
					// Porsche Połczyńska Volkswagen
					case 17:
						this.activeLogos.push(115);
						break;
					// Porsche Poznań Franowo Audi
					case 4:
						this.activeLogos.push(9);
						break;
					// Porsche Poznań Krańcowa Volkswagen
					case 1:
						this.activeLogos.push(115, 99, 12);
						break;
					// Porsche Poznań Obornicka skoda
					case 6:
						this.activeLogos.push(115, 99);
						break;
					// Porsche Rybnik Volkswagen
					case 21:
						this.activeLogos.push(115, 9, 99);
						break;
					// Porsche Sosnowiec Volkswagen
					case 24:
						this.activeLogos.push(115, 9);
						break;
					// Porsche Tarnów Volkswagen
					// case 31:
					// 	this.activeLogos.push(115, 99);
					// 	break;
					// Porsche Tychy Skoda
					case 39:
						this.activeLogos.push(99);
						break;
					// Porsche Tychy Volkswagen
					case 26:
						this.activeLogos.push(115);
						break;
					// Porsche Warszawa Okęcie Volkswagen
					case 29:
						this.activeLogos.push(115, 99);
						break;
					default:
				}
				this.$emit("setDealerForm", id, name, this.activeLogos, brandid);
				this.$store.dispatch("saturdayAction", saturday)
			},
		},
		directives: {
			ClickOutside,
		},
		mounted() {
			if (
				this.$route.query.dealer_id != undefined ||
				this.$route.query.brand_id != undefined
			) {
				this.$store.dispatch("loaderLoc", true);
				//console.log(JSON.stringify(this.$route.query));
				this.$store.dispatch("loadDealer", this.$route.query.dealer_id);
			} else {
				this.$store.dispatch("loadDealerList", this.$route.query.brand_id);
			}
		},

		computed: {
			...mapState(["dealerList", "oneDealer"]),
		},
		watch: {
			dealer_id: function(newVal) {
				if (newVal) {
					this.$emit("openModal", false);
				}
			},
			brand_id: function(newVal) {
				if (newVal) {
					this.$emit("openModal", false);
				}
			},
			// city: function(){
			// 	console.log('asda')
			// 	this.$store.dispatch("tempCityAction", '');
			// },
			oneDealer: {
				handler: function handler() {
					// console.log("zmiana onedealer");
					var that = this;
					// console.log(that.oneDealer.city);
					setTimeout(function() {
						that.$emit(
							"setDealerForm",
							that.oneDealer.id,
							that.oneDealer.name,
							"",
							that.oneDealer.brand_id,
							
						);
						that.$store.dispatch("tempCityAction", that.oneDealer.city);
						that.$store.dispatch("loadDealerList", that.$route.query.brand_id);
						that.$store.dispatch("loaderLoc", false);
					}, 2000);
				},
				deep: true,
			},
		},
	};
</script>

<style lang="scss">
	@import "@/assets/scss/_variables.scss";
	.arrowActive {
		transform: rotate(180deg);
		transition: transform 0.2s ease-in;
	}
	.accordion {
		max-width: 600px;
		// margin: 0 auto;
		background-color: $white;
		padding: 2rem;
		border: 1px solid $darkBlue;
		z-index: 999;
		overflow-y: scroll;
		max-height: 400px;
		&__close {
			position: absolute;
			right: 25px;
			top: 5px;
			cursor: pointer;
		}
		&__absolute {
			position: absolute;
			width: 100%;
			top: 50%;
			// left: 50%;
			// transform: translate(-50%, 100%);
			left: 0;
			z-index: 99999;
			@media (max-width: 1380px) {
				left: 20%;
			}
			@media (max-width: 860px) {
				left: 0;
			}
		}

		&__row {
			border-top: 1px solid $lightGrey;

			&:last-of-type {
				border-bottom: 1px solid $lightGrey;
			}
		}
		&__title {
			padding: 1.5rem 0;
			display: flex;
			justify-content: space-between;
			cursor: pointer;

			& h2 {
				font-size: 24px;
				font-weight: bold;
				color: $darkBlue;
			}
		}

		&__content {
			display: flex;
			justify-content: space-between;
			padding-bottom: 1rem;
		}

		&__text {
			color: $grey;
			font-size: 14px;
		}
		&__button {
			background: $lightBlue;
			display: inline-block;
			padding: 1rem 3rem;
			border-radius: 10px;
			color: $white;
			font-weight: bold;
			cursor: pointer;
			margin-top: 1rem;
		}

		&__images {
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			align-items: center;
		}
		&__logo {
			margin-right: 20px;
		}
	}
</style>
