<template>
	<div class="">
		<nav>
			<!-- <a href="/"> -->
			<img
				class="img"
				src="@/assets/images/piap-logo-2023-sm-v3.png"
				alt="Porsche Inter Auto"
				@click="$router.go('/')"
			/>
			<!-- </a> -->
		</nav>
		<div class="content">
			<div class="baner" id="baner">
				<img
					class="desktop"
					src="@/assets/images/baner_title.png"
					alt="Car Service Booking"
				/>
				<img
					class="mobile"
					src="@/assets/images/baner.png"
					alt="Car Service Booking"
				/>
				<!-- <FormFirstStep /> -->
				<!-- <div class="baner__text">
					<h1>
						PIA Online Service Booking Portal <br />
						<strong>
							Zarezerwuj wizytę w 3 prostych krokach
						</strong>
					</h1>
				</div> -->
			</div>
			<div class="baner__menu" id="baner__menu">
				<Localization
					:brand_id="brand_id"
					:dealer_id="dealer_id"
					class="input__dropdown"
					:openModal="modalOpen"
					@openModal="setOpenModal"
					@setDealerForm="setDealerId"
					:city="city"
				/>
				<!-- @hideModal="hideLocModal" -->
			</div>
		</div>
	</div>
</template>

<script>
	import Localization from "./Localization.vue";
	// import FormFirstStep from "./FormFirstStep.vue";

	export default {
		name: "baner",
		components: {
			Localization,
		},
		props: {
			opened: {
				type: Boolean,
				default: false,
			},
			dealer_id: {
				type: String,
			},
			brand_id: { type: String },
			city: {
				type: String,
			},
		},
		data() {
			return {
				modalOpen: false,
			};
		},
		watch: {
			opened: function(newVal) {
				this.modalOpen = newVal;
			},
		},
		methods: {
			hideLocModal() {
				if (this.opened === true) {
					// this.opened = false;
					this.$emit("opened", false);
					this.isModalOpen = false;
				}
			},
			setDealerId(id, name) {
				// this.dealerId = id;
				// console.log(this)
				this.$emit("dealer_id", id);
				this.$emit("dealer_name", name);
				// this.$emit("city", name);
				// this.opened = false;
				this.$emit("opened", false);
			},
			setOpenModal(val) {
				this.modalOpen = val;
				this.$emit("modalOpen", val);
			},
		},
	};
</script>

<style lang="scss">
	.desktop {
		@media (max-width: 1380px) {
			display: none;
		}
	}
	.mobile {
		@media (min-width: 1380px) {
			display: none;
		}
	}
	.content {
		display: flex;
		justify-content: center;
		@media (max-width: 1380px) {
			flex-direction: column;
		}
	}

	nav {
		width: 100%;
		text-align: center;
		padding: 25px 0;
		border-bottom: 4px solid #3b99d9;
	}

	.baner {
		position: relative;
		display: block;
		margin-top: -2px;
		// justify-content: center;
		// &__form {
		//   position: absolute;
		//   height: 100%;
		//   right: 0;
		// }

		&__menu {
			position: relative;
			padding: auto 65px;
			background: #1b3354 !important;
			width: 100%;
			z-index: 999;
			right: 0px;
			max-width: 538px;
			top: 0px;
			display: flex;
			margin-bottom: 5px;
			justify-content: center;
			@media (max-width: 768px) {
				// display: block;
			}
			@media (max-width: 1580px) {
				padding: 0px 0px;
			}
			@media (max-width: 1380px) {
				max-width: 100%;
				margin-top: -20px;
			}
			@media (min-width: 1760px) {
				padding: 40px 65px;
			}
		}

		&__text {
			position: absolute;
			bottom: 40px;
			left: 5%;
			text-align: left;
			width: 95%;

			h1 {
				color: #fff;
				font-size: 3.5rem;
				font-weight: 100;

				@media (max-width: 1200px) {
					font-size: 2.5rem;
				}
				@media (max-width: 768px) {
					font-size: 2rem;
					color: #000;
				}
				@media (max-width: 525px) {
					font-size: 1.5rem;
				}
			}

			@media (max-width: 768px) {
				position: relative;
				bottom: 0;
				padding: 1rem 0;
			}
		}
		img {
			// width: 100%;
			max-width: 100%;
			position: relative !important;
		}
		.img {
			height: 42px !important;
			position: relative !important;
		}
	}
</style>
