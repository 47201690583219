import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		dealerList: [],
		oneDealer: [],
		dates: [],
		cars: [],
		loaderLoc: false,
		tempCity: '',
		// url:'https://webapi.demo.csflow.pl/'
		url: "https://webapi.porscheinterauto.com.pl/",
		saturdayInfo: ''
	},
	getters: {
		dealerList: (state) => {
			return state.dealerList;
		},
		dates: (state) => {
			return state.dates;
		},
		cars: (state) => {
			return state.cars;
		},
		oneDealer: (state) => {
			return state.oneDealer;
		},
		getLoaderLoc: (state) => {
			return state.loaderLoc;
		},
		getSaturdayInfo: (state) => {
			return state.saturdayInfo;
		},
		getTempCity: (state) => {
			return state.tempCity
		}
	},
	mutations: {
		setDealerList(state, dealerList) {
			state.dealerList = dealerList;
		},
		setDates(state, dates) {
			state.dates = dates;
		},
		setCars(state, cars) {
			state.cars = cars;
		},
		setDealer(state, dealer) {
			state.oneDealer = dealer;
		},
		setLoaderLoc(state, dealer) {
			state.loaderLoc = dealer;
		},
		setSaturdayInfo(state, saturday) {
			state.saturdayInfo = saturday;
		},
		setTempCity(state, city){
			state.tempCity = city
		}
	},
	actions: {
		loadDealerList({ commit, state }, brandid) {
			// axios.get(state.url + 'dealers?service=1')
			axios
				.get(state.url + "dealers?service=1", { params: { brand_id: brandid } })
				// axios.get(state.url + 'dealers?service=1', { params: { brand_id: brandid }} )
				.then((res) => res.data.dealers)
				.then((dealerList) => {

					// var removeIndex = dealerList.findIndex( item => item.id === 31 );
					// dealerList.splice(removeIndex, 1)
					// console.log(dealerList);

					commit("setDealerList", dealerList);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadDealer({ commit, state }, id) {
			axios
				.get(state.url + "dealer?", { params: { id: id } })
				.then((res) => res.data.dealer)
				.then((oneDealer) => {
					// console.log(oneDealer);
					commit("setDealer", oneDealer);
					commit("setSaturdayInfo", oneDealer.service_hours_saturday);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadDates({ commit, state }, dealerid) {
			// axios.get(state.url + 'dealers?service=1')
			axios
				.get(state.url + "service-occupancy?", {
					params: { dealer_id: dealerid },
				})
				.then((res) => res.data.occupancy)
				.then((dates) => {
					// console.log(dates);
					commit("setDates", dates);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loadCars({ commit, state }, payload) {
			axios
				.get(state.url + "service-cars?", {
					params: {
						dealer_id: payload.dealer_id,
						date: payload.date,
						hour: payload.hour,
						hour_to: "",
					},
				})
				.then((res) => res.data.cars)
				.then((cars) => {
					// console.log(cars);
					commit("setCars", cars);
				})
				.catch((error) => {
					console.log(error);
				});
		},
		loaderLoc({ commit }, data) {
			commit("setLoaderLoc", data);
		},
		saturdayAction({ commit }, data) {
			commit("setSaturdayInfo", data);
		},
		tempCityAction({ commit }, data) {
			commit("setTempCity", data);
		},
	},
	modules: {},
});
