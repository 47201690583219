<template>
  <div>
    <div class="container ">
      <div class="form" v-if="!send && !message">
        <div class="form__row">
          <!-- <div class="form__col--3"> -->
          <!-- formdata.brand_Id = {{ formData.brand_id }} -->
          <!-- {{ dealerList }} -->
          <!-- <h2 class="form__col--header">
							<span class="blue">
								1.
							</span>
							Wybierz markę, lokalizację oraz <br />
							termin wizyty
						</h2>
					</div> -->
          <div class="form__col--1 ">
            <!-- <div class="logos row__wrap" v-if="!brandInUrl">
							<div
								class="logos__item"
								v-for="(logo, index) in logos"
								:key="index"
								:brand="logo.id"
								@click="
									selectItem(index);
									setBrandId(logo.id);
									fetchDealers();
									resetDealer();
								"
							>
								<img
									class="logo"
									:class="{
										active:
											index === isLogoActive ||
											activeLogos.indexOf(logo.id) !== -1,
									}"
									:src="getImgUrl(logo.link)"
									:alt="logo.name"
								/> -->
            <!-- <div class="logos__check" :class="{ active: index === isLogoActive || activeLogos.indexOf(logo.id) !== -1 }">
                  <img class="logos__tick" src="@/assets/images/elem/icon-check.svg" alt="">
                </div> -->
            <!-- </div>
						</div> -->
            <div class="input__row " v-if="!getLoaderLoc">
              <h1 class="input__mobileheader mobile">
                <span>Car service booking</span> <br />
                <b>Zarezerwuj wizytę serwisową w 3 prostych krokach</b>
              </h1>
              <h1 class="input__header ">
                Wybierz markę, lokalizację oraz <br />
                termin wizyty
              </h1>
              <div class="input__col">
                <div class="icon__wrap">
                  <img
                    src="@/assets/images/elem/icon-brand.svg"
                    alt="Wybierz makrę"
                  />
                </div>
                <div class="input__item">
                  <label for="">
                    <span class="form__label--white">
                      Wybierz markę
                    </span>
                    <div class="input__flex">
                      <!-- <input
												type="text"
												class="input__select"
												v-model="formData.dealer_name"
												name=""
												value=""
												placeholder="- wybierz -"
												disabled
											/> -->
                      <select
                        :class="[
                          'input__select',
                          formData.brand_id ? 'selected' : '',
                        ]"
                        v-model="formData.brand_id"
                      >
                        <option value="" disabled selected>- wybierz -</option>
                        <option
                          v-for="brand in logos"
                          :key="brand.id"
                          :value="brand.id"
                          >{{ brand.name }}</option
                        >
                      </select>
                    </div>
                  </label>
                </div>
              </div>
              <div class="input__col">
                <div class="icon__wrap">
                  <img
                    src="@/assets/images/elem/google-place.png"
                    alt="Lokalizacja serwisu"
                  />
                </div>
                <div class="input__item">
                  <label for="">
                    <span class="form__label--white">
                      Lokalizacja serwisu
                    </span>
                    <div class="input__flex">
                      <!-- <input
												type="text"
												class="input__select"
												v-model="formData.dealer_name"
												name=""
												value=""
												placeholder="- wybierz -"
												disabled
											/> -->
                      <select
                        :class="[
                          'input__select',
                          formData.city ? 'selected' : '',
                        ]"
                        name=""
                        v-model="formData.city"
                        @change="resetTempCity"
                      >
                        <option value="" disabled="disabled"
                          >- wybierz -</option
                        >
                        <!-- v-for="dealer in dealerList.filter(
														(item) => item.brand_id === formData.brand_id
													)" -->
                        <option
                          v-for="city in cities"
                          :key="city"
                          :value="city"
                          >{{ city }}</option
                        >
                      </select>
                    </div>
                  </label>
                </div>
              </div>
              <div class="input__col">
                <div class="icon__wrap">
                  <img
                    src="@/assets/images/elem/icon-serwis.svg"
                    alt="serwis"
                  />
                </div>
                <div class="input__item">
                  <label for="">
                    <span class="form__label--white">
                      Serwis
                    </span>
                    <div class="input__flex">
                      <div class="input__disabled">
                        <input
                          disabled
                          type="text"
                          :class="[
                            'input__select',
                            formData.dealer_name ? 'selected' : '',
                          ]"
                          v-model="formData.dealer_name"
                          name=""
                          value=""
                          placeholder="- wybierz -"
                        />
                        <div
                          @click="formData.city ? openLocModal() : ''"
                          class="divwrap"
                        ></div>
                      </div>

                      <!-- disabled -->
                      <!-- <select class="input__select" name="" v-model="formData.dealer_id" >
                      <option value="" disabled="disabled">- wybierz -</option>
                      <option v-for="dealer in dealerList" :key="dealer.id" :value="dealer.id">{{ dealer.name }}</option>
                    </select> -->
                    </div>
                  </label>
                </div>
              </div>
              <div class="input__col">
                <div class="icon__wrap">
                  <img
                    src="@/assets/images/elem/calendar.png"
                    alt="Lokalizacja serwisu"
                  />
                </div>
                <div class="input__item">
                  <label for="">
                    <div class="form__label--flex">
                      <span class="form__label--white">
                        Termin wizyty
                      </span>
                      <p class="calendar__daytime" v-if="formData.hour">
                        | Wybrana pora dnia: <span>{{ formData.hour }}</span>
                      </p>
                    </div>
                    <div class="input__calendar">
                      <div class="input__disabled">
                        <input
                          type="text"
                          :class="[
                            'input__select',
                            formData.date ? 'selected' : '',
                          ]"
                          v-model="formData.date"
                          name=""
                          value=""
                          disabled
                          placeholder="- wybierz -"
                          @click="openModal = true"
                        />
                        <div @click="openModal = true" class="divwrap"></div>
                      </div>

                      <Calendar
                        :showCalendar="!showCalendar && openModal"
                        :reset="resetDate"
                        @showCalendar="openModal = false"
                        @chooseDate="setDateForm"
                        @chooseTimeOfDay="setTimeOfDay"
                      />
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="input__row row__wrap" v-else>
              <div class="lds">
                <div></div>
                <div></div>
                <div></div>
              </div>
            </div>
            <!-- <Localization
							class="input__dropdown"
							:openModal="opened"
							@setDealerForm="setDealerId"
							@hideModal="hideLocModal"
						/> -->
          </div>
        </div>
        <transition name="fade">
          <div class="" v-if="showSection">
            <div class="form__row">
              <div class="form__col--3">
                <h2 class="form__col--header">
                  <!-- <span class="blue">
										2.
									</span> -->
                  Wybierz zakres usług
                </h2>
              </div>
              <div class="form__col--7 relative">
                <h2 class="form__col--header text__center">
                  SERWIS MECHANICZNY
                </h2>
                <div class="services">
                  <div class="services__item">
                    <div class="services__relative">
                      <img
                        src="@/assets/images/ekspres-service.jpg"
                        alt="Ekspres Service"
                      />
                      <div class="services__absolute">
                        <div
                          class=""
                          @click="
                            selectServiceModal(1);
                            showModal = true;
                          "
                        >
                          <span class="services__link">
                            Szczegóły
                          </span>
                        </div>
                      </div>
                      <div class="services__overlay"></div>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Ekspres Service"
                          v-model="formData.checkedServices"
                        />
                        <span class="checkbox__title">Ekspres Service</span>
                      </label>
                    </div>
                  </div>
                  <div class="services__item relative">
                    <div class="services__relative">
                      <img
                        src="@/assets/images/przeglad-okresowy.jpg"
                        alt="Przegląd okresowy"
                      />
                      <div class="services__absolute">
                        <div
                          class=""
                          @click="
                            selectServiceModal(2);
                            showModal = true;
                          "
                        >
                          <span class="services__link">
                            Szczegóły
                          </span>
                        </div>
                      </div>
                      <div class="services__overlay"></div>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Przegląd okresowy"
                          v-model="formData.checkedServices"
                          @click="isService('Przegląd okresowy')"
                        />
                        <span class="checkbox__title">
                          Przegląd okresowy
                          <span v-if="formData.pickedMileage != ''"
                            >| {{ formData.pickedMileage }}</span
                          >
                          <small
                            class="changeMileage pointer"
                            v-if="formData.pickedMileage != ''"
                            @click="
                              hideRadios = false;
                              formData.pickedMileage = '';
                            "
                            >Zmień</small
                          >
                        </span>
                      </label>
                    </div>
                    <!-- <div
											class="services__more--full"
											:class="{ hide: hideRadios }"
											v-if="reviewRole"
										> -->
                    <fieldset
                      class="km"
                      :class="{ hide: hideRadios }"
                      v-if="reviewRole"
                    >
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="30k"
                          name=""
                          value="do 30 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="30k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 30 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="60k"
                          name=""
                          value="do 60 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="60k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 60 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="90k"
                          name=""
                          value="do 90 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="90k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 90 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="120k"
                          name=""
                          value="do 120 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="120k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 120 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="150k"
                          name=""
                          value="do 150 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="150k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 150 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="180k"
                          name=""
                          value="do 180 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="180k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 180 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="210k"
                          name=""
                          value="do 210 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="210k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 210 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                      <div class="radio__wrap">
                        <input
                          type="radio"
                          id="240k"
                          name=""
                          value="do 240 000 km"
                          v-model="formData.pickedMileage"
                          @click="hideRadios = true"
                        />
                        <label for="240k">
                          <span></span>
                          <div class="">
                            <div class="radio__label">
                              do 240 000 km
                            </div>
                          </div>
                        </label>
                      </div>
                    </fieldset>
                    <!-- </div> -->
                  </div>
                  <div class="services__item">
                    <div class="services__relative">
                      <img
                        src="@/assets/images/elektryka.jpg"
                        alt="Elektryka lub elektronika pojazdu"
                      />
                      <div class="services__absolute">
                        <div
                          class=""
                          @click="
                            selectServiceModal(3);
                            showModal = true;
                          "
                        >
                          <span class="services__link">
                            Szczegóły
                          </span>
                        </div>
                      </div>
                      <div class="services__overlay"></div>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Elektryka lub elektronika pojazdu"
                          v-model="formData.checkedServices"
                        />
                        <span class="checkbox__title"
                          >Elektryka lub elektronika pojazdu</span
                        >
                      </label>
                    </div>
                  </div>
                  <div class="services__item">
                    <div class="">
                      <img src="@/assets/images/wymiana-opon.jpg" alt="" />
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Wymiana opon"
                          v-model="formData.checkedServices"
                        />
                        <span class="checkbox__title">Wymiana opon</span>
                      </label>
                    </div>
                  </div>
                  <div class="services__item">
                    <div class="">
                      <img
                        src="@/assets/images/naprawa-mechaniczna.jpg"
                        alt=""
                      />
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Naprawa mechaniczna"
                          v-model="formData.checkedServices"
                        />
                        <span class="checkbox__title">Naprawa mechaniczna</span>
                      </label>
                    </div>
                  </div>
                  <div class="services__item">
                    <div class="services__relative">
                      <img src="@/assets/images/inne.jpg" alt="" />
                      <div class="services__absolute">
                        <div
                          class=""
                          @click="
                            selectServiceModal(4);
                            showModal = true;
                          "
                        >
                          <span class="services__link">
                            Szczegóły
                          </span>
                        </div>
                      </div>
                      <div class="services__overlay"></div>
                    </div>
                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Inne"
                          @click="isOther('Inne')"
                          v-model="formData.checkedServices"
                        />
                        <span class="checkbox__title">Inne</span>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="" v-if="otherRole">
                  <label>
                    <small class="extra_message">Opisz zakres usług</small>
                    <br />
                    <textarea
                      class="checkbox__msg"
                      name="description"
                      id="description"
                      placeholder="- wpisz -"
                      v-model="formData.extramessage"
                    ></textarea>
                  </label>
                </div>
                <h2 class="form__col--header text__center">
                  MOBILNOŚĆ
                </h2>
                <p class="services__text text__center">
                  Z myślą o Państwa komforcie przygotowaliśmy dodatkowe usługi:
                </p>
                <div class="extraservices">
                  <label
                    class="extraservices__content extraservices__content--margin"
                  >
                    <div class="">
                      <div class="checkbox">
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value="Door to Door"
                          v-model="formData.door_to_door"
                          true-value="1"
                          false-value="0"
                        />
                        <span class="checkbox__title">Door to Door</span>
                      </div>
                    </div>
                    <div class="extraservices__text">
                      <p>
                        Dzięki usłudze Door to Door nie tracisz czasu na dojazdy
                        do serwisu i oczekiwanie.
                      </p>
                      <br />
                      <p>
                        Obejmuje ona transport samochodu do serwisu przez
                        naszych wykwalifikowanych pracowników, oraz dostarczenie
                        auta w wyznaczone miejsce, po wizycie auta w serwisie.
                      </p>
                    </div>
                  </label>

                  <!-- <div class="extraservices__content" > -->
                  <label class="extraservices__content" v-if="cars.length > 0">
                    <div class="">
                      <div class="checkbox">
                        <label>
                          <input
                            type="checkbox"
                            class="checkbox__input"
                            name=""
                            value="Samochód zastępczy"
                            v-model="formData.replacement_car"
                            true-value="1"
                            false-value="0"
                          />
                          <span class="checkbox__title"
                            >Samochód zastępczy</span
                          >
                        </label>
                      </div>
                    </div>
                    <div class="extraservices__text">
                      <p>
                        Możesz skorzystać z samochodu zastępczego. Wciąż
                        rozbudowujemy ich flotę.
                      </p>
                      <br />
                      <p>
                        Koszt wynajmu i dostępność wybranego samochodu
                        zastępczego zostanie potwierdzona przez naszego Doradcę.
                      </p>
                    </div>
                  </label>
                </div>
                <div
                  class="extraservices-cars"
                  v-if="formData.replacement_car != 0"
                >
                  <div class="extraservices-cars__flex">
                    <div class="extraservices-cars__col">
                      <div class="extraservices-cars__items">
                        <div
                          class="extraservices-cars__car"
                          v-for="(car, i) in cars"
                          :key="i"
                          @click="
                            selectLoanCar(i);
                            setLoanCar(car.id, car.brand, car.model);
                          "
                          :class="{ active: i === isLoanCar }"
                        >
                          <div class="text__center">
                            <img
                              :src="getModelImg(car.brand, car.model)"
                              @error="onImgError()"
                              alt=""
                            />
                          </div>
                          <p class="text__center">
                            {{ car.brand }} {{ car.model }}
                          </p>
                          <!-- <p class="text__center">
                        od <span class="extraservices-cars__price">{{ car.rate_from }}zł</span>/doba
                      </p> -->

                          <div
                            class="logos__check"
                            :class="{ active: i === isLoanCar }"
                          >
                            <img
                              class="logos__tick"
                              src="@/assets/images/elem/icon-check.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  class="details details__position details__shadow"
                  v-if="showModal"
                >
                  <div class="details__relative">
                    <div class="details__absolute" @click="showModal = false">
                      <img src="@/assets/images/elem/exit.png" alt="" />
                    </div>
                    <p class="details__text" v-if="servicemodal == 1">
                      Gdy chodzi o bezpieczeństwo czas ma kluczową rolę. Usługa
                      express service to szybkie naprawy poza wyznaczonym przez
                      producenta planem serwisowania.
                      <br /><br />
                      Wymiana klocków hamulcowych, oleju lub oświetlenia Twojego
                      samochodu. Każdego z tych zadań podejmą się nasi
                      wykwalifikowani serwisanci
                    </p>
                    <p class="details__text" v-if="servicemodal == 2">
                      Wyznaczona przez producenta obsługa serwisowa związana z
                      aktualnym przebiegiem lub wiekiem pojazdu.
                    </p>
                    <p class="details__text" v-if="servicemodal == 3">
                      Pojawiła się kontrolka której nie znasz? <br />
                      Nie ryzykuj. Oddaj auto w ręce naszych specjalistów
                    </p>
                    <p class="details__text" v-if="servicemodal == 4">
                      Jako Autoryzowana Stacja Obsługi dysponujemy pełną wiedzą,
                      na temat każdego elementu Twojego samochodu. Opisz
                      dokładnie czego potrzebujesz, a nasi specjaliści szybko i
                      sprawnie udzielą Ci wsparcia.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="form__row">
              <div class="form__col--3">
                <h2 class="form__col--header">
                  <!-- <span class="blue">
										3.
									</span> -->
                  Podaj swoje dane
                </h2>
              </div>
              <div class="form__col--7">
                <div class="form__contact" v-if="!message">
                  <div class="form__inputs">
                    <div class="form__item">
                      <label>
                        <span class="form__label">Imię</span>
                        <br />
                        <span
                          class="text-error"
                          v-if="errors.first_name && errors.first_name.errors"
                        >
                          {{ errors.first_name.message }}
                          <br />
                        </span>
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.first_name"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                    <div class="form__item">
                      <label>
                        <span class="form__label">Nazwisko</span>
                        <br />
                        <span
                          class="text-error"
                          v-if="errors.last_name && errors.last_name.errors"
                        >
                          {{ errors.last_name.message }}
                          <br />
                        </span>
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.last_name"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                    <div class="form__item">
                      <label>
                        <span class="form__label">Numer telefonu</span>
                        <br />
                        <span
                          class="text-error"
                          v-if="
                            errors.phone_number && errors.phone_number.errors
                          "
                        >
                          {{ errors.phone_number.message }}
                          <br />
                        </span>
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            maxlength="9"
                            minlength="9"
                            v-model="formData.phone_number"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                    <div class="form__item">
                      <label>
                        <span class="form__label">Adres e-mail</span>
                        <br />
                        <span
                          class="text-error"
                          v-if="errors.email && errors.email.errors"
                        >
                          {{ errors.email.message }}
                          <br />
                        </span>
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.email"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>

                    <div class="form__item">
                      <label>
                        <span class="form__label">Numer rejestracyjny</span>
                        <br />
                        <span
                          class="text-error"
                          v-if="
                            errors.registration_number &&
                              errors.registration_number.errors
                          "
                        >
                          {{ errors.registration_number.message }}
                          <br />
                        </span>
                        <div class="form__wrap form__wrap--reset">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.registration_number"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <br />
                  <div class="form__inputs" v-if="formData.door_to_door == 1">
                    <div class="form__item">
                      <label>
                        <span class="form__label">Miejscowość</span>
                        <br />
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.city"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                    <div class="form__item">
                      <label>
                        <span class="form__label">Ulica</span>
                        <br />
                        <div class="form__wrap">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.street"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                    <div class="form__item">
                      <label>
                        <span class="form__label">Numer domu</span>
                        <br />
                        <div class="form__wrap form__wrap--reset">
                          <input
                            class="form__input"
                            type="text"
                            name=""
                            value=""
                            placeholder="- wpisz -"
                            v-model="formData.homenumber"
                          />
                          <p class="form__error"></p>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="form__rodo">
                    <p>
                      <small>
                        Aby móc przetwarzać Państwa dane w celu przesyłania
                        Państwu openformacji dotyczących promocji, akcji
                        marketingowych, organizowanych wydarzeń w sprawie naszej
                        działalności oraz oferowanych przez nas produktów i
                        usług za pośrednictwem wskazanych poniżej kanałów
                        komunikacji, prosimy Państwa o wskazanie preferowanych
                        form komunikacji z Państwem poprzez wyrażenie poniżej
                        wskazanych zgód.
                        <br /><br />
                        W każdej chwili mogą Państwo cofnąć zgodę na
                        przetwarzanie danych osobowych. Cofnięcie zgody nie
                        wpłynie jednak na zgodność z prawem przetwarzania przez
                        nas danych osobowych przed wycofaniem zgody.
                      </small>
                    </p>

                    <div class="checkbox">
                      <label>
                        <input
                          type="checkbox"
                          class="checkbox__input"
                          name=""
                          value=""
                          v-model="accept"
                          true-value="1"
                          false-value="0"
                        />
                        <span>
                          Zapoznałem/Zapoznałam się z poniższymi oświadczeniami
                          i akceptuję je wszystkie
                        </span>
                      </label>
                    </div>

                    <p>
                      <small>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych w
                        postaci adresu e-mail lub numeru telefonu (w zależności
                        od wybranego kanału komunikacji) przez Porsche Inter
                        Auto Polska Sp. z o.o. ("Porsche") dla celów
                        marketingowych związanych z przesyłaniem/przekazywaniem
                        przez Porsche spersonalizowanych openformacji
                        handlowo-marketingowych dotyczących produktów i usług
                        oferowanych przez Porsche, za pośrednictwem:
                      </small>
                    </p>

                    <div class="checkbox__row">
                      <div class="checkbox__col">
                        <div class="checkbox">
                          <!-- <input type="checkbox" name="accept-phone5" class="checkbox__input" value="" v-model="formData['accept-phone5']" true-value="1" false-value="0"> -->
                          <label>
                            <input
                              type="checkbox"
                              name="accept-phone9"
                              class="checkbox__input"
                              value=""
                              v-model="formData['accept-phone9']"
                              true-value="1"
                              false-value="0"
                            />
                            <span>
                              Telefon
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="checkbox__col">
                        <div class="checkbox">
                          <!-- <input type="checkbox" name="accept-email5" class="checkbox__input" value="" v-model="formData['accept-email5']" true-value="1" false-value="0"> -->
                          <label>
                            <input
                              type="checkbox"
                              name="accept-email9"
                              class="checkbox__input"
                              value=""
                              v-model="formData['accept-email9']"
                              true-value="1"
                              false-value="0"
                            />
                            <span>
                              E-mail
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <p>
                      <small>
                        Wyrażam zgodę na przetwarzanie moich danych osobowych w
                        postaci adresu e-mail lub numeru telefonu (w zależności
                        od wybranego kanału komunikacji) przez Porsche Inter
                        Auto Polska Sp. z o.o. („Porsche”) w celu informowania
                        mnie o ważnych terminach warsztatowych jak np. terminy
                        przeglądu, terminy wymiany opon, umówione wizyty itp. za
                        pośrednictwem:
                      </small>
                    </p>

                    <div class="checkbox__row">
                      <div class="checkbox__col">
                        <div class="checkbox">
                          <!-- <input type="checkbox" name="accept-phone6" class="checkbox__input" value="" v-model="formData['accept-phone6']" true-value="1" false-value="0"> -->
                          <label>
                            <input
                              type="checkbox"
                              name="accept-phone10"
                              class="checkbox__input"
                              value=""
                              v-model="formData['accept-phone10']"
                              true-value="1"
                              false-value="0"
                            />
                            <span>
                              Telefon
                            </span>
                          </label>
                        </div>
                      </div>
                      <div class="checkbox__col">
                        <div class="checkbox">
                          <!-- <input type="checkbox" name="accept-email6" class="checkbox__input" value="" v-model="formData['accept-email6']" true-value="1" false-value="0"> -->
                          <label>
                            <input
                              type="checkbox"
                              name="accept-email10"
                              class="checkbox__input"
                              value=""
                              v-model="formData['accept-email10']"
                              true-value="1"
                              false-value="0"
                            />
                            <span>
                              E-mail
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <p>
                      <small>
                        Administratorem Państwa danych osobowych jest spółka
                        Porsche Inter Auto Polska Sp. z o.o. z siedzibą w
                        Warszawie (01-304) przy ul. Połczyńskiej 118a,
                        zarejestrowana w Sądzie Rejonowym dla m.st. Warszawy,
                        XIII Wydział Gospodarczy Krajowego Rejestru Sądowego,
                        KRS 0000108213. Mogą się Państwo z nami skontaktować
                        przy pomocy numeru telefonu +48 22 223 15 15 oraz adresu
                        e-mail: rodo@porscheinterauto.pl.
                        <span v-if="acceptToggle"
                          >openformujemy, że podane przez Państwa dane będziemy
                          przetwarzać w celu obsługi Państwa zapytania, w tym w
                          celu kontaktu z Państwem w tym zakresie. Będziemy
                          przetwarzać Państwa dane osobowe także w innych
                          celach, jeśli wyrażą na to Państwo odrębną zgodę.
                          Podanie przez Państwa danych osobowych jest
                          dobrowolne. Przysługuje Państwu prawo do żądania od
                          nas dostępu do swoich danych osobowych, prawo do ich
                          sprostowania, usunięcia, ograniczenia przetwarzania,
                          prawo do przenoszenia danych a także prawo do
                          sprzeciwu wobec przetwarzania przez nas Państwa danych
                          osobowych. Więcej na ten temat przeczytają Państwo w
                          naszej Polityce Prywatności
                          (http://www.porscheinterauto.pl/rodo).</span
                        >
                        <br />
                        <strong
                          class="pointer"
                          v-on:click="acceptToggle = !acceptToggle"
                          v-if="!acceptToggle"
                          >Rozwiń</strong
                        >
                        <strong
                          class="pointer"
                          v-on:click="acceptToggle = !acceptToggle"
                          v-if="acceptToggle"
                          >Zwiń</strong
                        >
                      </small>
                    </p>
                  </div>

                  <!-- <div class="form__btn__wrap" v-if="!send"> -->
                  <div class="form__btn__wrap" v-if="!disablebtn">
                    <button
                      class="form__btn"
                      type="button"
                      name="button"
                      @click="sendForm"
                    >
                      Wyślij
                    </button>
                  </div>
                  <div v-if="message">
                    <h2 class="text-center successform" v-html="message"></h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
      <div class="form__thanks text__center" v-else>
        <h2>
          Dziękujemy za rezerwację wizyty w serwisie.
        </h2>
        <br />
        Nasz pracownik skontaktuje się z Państwem w celu potwierdzenia
        szczegółów.<br />
        <div>
          Zaproponowany termin jest orientacyjny i zostanie potwierdzony
          telefonicznie przez pracownika serwisu najszybciej jak to możliwe.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import Localization from "@/components/Localization.vue";
import Calendar from "@/components/Calendar.vue";
import { mapState, mapGetters } from "vuex";

export default {
  components: {
    // Localization,
    Calendar,
  },
  props: {
    dealer_name: {
      type: String,
      default: "",
    },
    dealer_id: {
      type: String,
      default: "",
    },
    city: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      cities: [],
      disablebtn: false,
      brandInUrl: false,
      showSection: false,
      showCalendar: true,
      send: false,
      message: false,
      isLogoActive: null,
      acceptToggle: false,
      showModal: false,
      servicemodal: null,
      reviewRole: false,
      otherRole: false,
      hideRadios: false,
      opened: false,
      openModal: false,
      isModalOpen: false,
      isLoanCar: false,
      activeLogos: [],
      resetDate: "",
      errors: {},
      accept: 0,
      imgError: false,
      countclick: 0,
      formData: {
        dealer_id: "",
        brand_id: "",
        dealer_name: "",
        ip: "85.221.212.38",
        url: window.location.href,
        first_name: "",
        last_name: "",
        email: "",
        phone_number: "",
        description: "",
        checkedServices: [],
        extraServices: [],
        door_to_door: 0,
        replacement_car: 0,
        registration_number: "",
        date: "",
        hour: "",
        // "accept-phone5": 0,
        // "accept-email5": 0,
        // "accept-phone6": 0,
        // "accept-email6": 0,
        city: "",
        street: "",
        homenumber: "",
        "accept-phone9": 0,
        "accept-email9": 0,
        "accept-phone10": 0,
        "accept-email10": 0,
        pickedMileage: "",
        loanCar: "",
        extramessage: "",
      },
      logos: [
        {
          id: 115,
          name: "Volkswagen",
          link: "vw",
        },
        {
          id: 9,
          name: "Audi",
          link: "audi",
        },
        {
          id: 99,
          name: "Skoda",
          link: "skoda",
        },
        {
          id: 12,
          name: "Bentley",
          link: "bentley",
        },
        {
          id: 54,
          name: "Lamborghini",
          link: "lambo",
        },
        {
          id: 87,
          name: "Porsche",
          link: "porsche",
        },
        {
          id: 235,
          name: "Volkswagen Dostawcze",
          link: "vwd",
        },
      ],
    };
  },
  validate: {
    rules: {
      first_name: {
        required: true,
      },
      last_name: {
        required: true,
      },
      registration_number: {
        required: true,
      },
      email: {
        requireGroup: "d",
        email: true,
      },
      phone_number: {
        requireGroup: "p",
        phone: true,
      },
    },
    messages: {
      first_name: {
        required: "To pole jest wymagane!",
      },
      last_name: {
        required: "To pole jest wymagane!",
      },
      registration_number: {
        required: "To pole jest wymagane!",
      },
      email: {
        requireGroup: "To pole jest wymagane!",
        email: "Błędny adres E-mail",
      },
      phone_number: {
        requireGroup: "To pole jest wymagane!",
        phone: "Błędny numer telefonu",
      },
    },
  },
  watch: {
    dealerList: function() {
      this.cities = [];
      this.dealerList.forEach((item) => {
        this.cities.includes(item.city) ? "" : this.cities.push(item.city);
      });
    },
    dealer_name: function(newVal) {
      this.formData.dealer_name = newVal;
    },
    dealer_id: function(newVal) {
      this.formData.dealer_id = newVal;
    },
    getTempCity: function() {
      // console.log(this.getTempCity)
      if (this.getTempCity != "") {
        this.formData.city = this.getTempCity;
      }
    },
    accept: {
      handler: function() {
        if (this.accept == 0) {
          // this.formData['accept-phone5'] = 0;
          // this.formData['accept-email5'] = 0;
          // this.formData['accept-phone6'] = 0;
          // this.formData['accept-email6'] = 0;
          this.formData["accept-phone9"] = 0;
          this.formData["accept-email9"] = 0;
          this.formData["accept-phone10"] = 0;
          this.formData["accept-email10"] = 0;
        } else if (this.accept == 1) {
          // this.formData['accept-phone5'] = 1;
          // this.formData['accept-email5'] = 1;
          // this.formData['accept-phone6'] = 1;
          // this.formData['accept-email6'] = 1;
          this.formData["accept-phone9"] = 1;
          this.formData["accept-email9"] = 1;
          this.formData["accept-phone10"] = 1;
          this.formData["accept-email10"] = 1;
        }
      },
    },
    "formData.dealer_id": function(newVal) {
      this.$emit("dealer_id", newVal);
      this.formData.date = "";
      this.resetDate = Math.floor(Math.random() * 100);
    },
    "formData.brand_id": function(newVal) {
      this.$emit("brand_id", toString(newVal));
      this.formData.city = "";
      this.formData.date = "";
      this.resetDate = Math.floor(Math.random() * 100);

      this.fetchDealers();
    },
    "formData.city": function(newVal) {
      if (this.getTempCity == "") {
        this.$emit("city", newVal);
        this.formData.dealer_name = "";
        this.formData.date = "";
        this.resetDate = Math.floor(Math.random() * 100);
      }
    },
    "formData.hour": {
      handler: function() {
        this.showSection = true;
        this.fetchCars();
      },
    },
    "formData.dealer_name": {
      handler: function() {
        var that = this;
        this.showCalendar = false;
        this.formData.checkedServices = [];
        this.formData.replacement_car = 0;
        this.formData.door_to_door = 0;
        this.formData.date = "";
        this.formData.hour = "";
        this.formData.pickedMileage = "";
        this.formData.loanCar = "";
        this.resetDate = Math.floor(Math.random() * 100);
        setTimeout(function() {
          that.showSection = false;
        }, 200);
      },
    },
  },
  methods: {
    resetTempCity() {
    //   console.log("zmianamiasta");
      this.$store.dispatch("tempCityAction", "");
    },
    hideCalendar(val) {
      this.openModal = val;
    },
    sendForm() {
      this.$gtag.event("Klikniecie wyślij formularz");

      var that = this;

      this.errors = {};
      this.errors = this.$vaLittle.check(this.formData);
      if (!this.errors.errors) {
        this.countclick += 1;
        this.disablebtn = true;
        this.send = true;

        var tmpServices = "";
        if (this.formData.checkedServices.length > 0) {
          var index = this.formData.checkedServices.indexOf(
            "Przegląd okresowy"
          );
          var index2 = this.formData.checkedServices.indexOf("Inne");
          if (index !== -1) {
            this.formData.checkedServices[index] =
              this.formData.checkedServices[index] +
              " " +
              this.formData.pickedMileage;
          }
          if (index2 !== -1) {
            this.formData.checkedServices[index2] =
              this.formData.checkedServices[index2] +
              ": " +
              this.formData.extramessage;
          }
          tmpServices = this.formData.checkedServices.join(", ");
        }

        this.formData.description =
          "ZGŁOSZONY TERMIN PRZYJĘCIA: \r\n" +
          this.formData.date +
          ", wybrana pora dnia: " +
          this.formData.hour +
          ". \r\nZAKRES USŁUGI: " +
          tmpServices;

        // if (this.formData.loanCar != '') {
        //   this.formData.description = this.formData.description + " \r\nOCZEKIWANY SAMOCHÓD ZASTĘPCZY:\r\n" + this.formData.loanCar;
        // }

        if (this.formData.door_to_door != 0) {
          this.formData.description =
            this.formData.description +
            " \r\nADRES:\r\n" +
            "Miejscowość: " +
            this.formData.city +
            "\r\n" +
            "Ulica: " +
            this.formData.street +
            "\r\n" +
            "Numer domu: " +
            this.formData.homenumber;
        }

        var form_data = new FormData();
        Object.keys(that.formData).forEach((key) => {
          form_data.append(key, that.formData[key]);
        });

        var xmlHttp = new XMLHttpRequest();

        xmlHttp.onreadystatechange = function() {
          if (xmlHttp.readyState == 4 && xmlHttp.status == 200) {
            that.message = JSON.parse(xmlHttp.responseText);
          }
        };
        xmlHttp.open("post", "/send");
        // xmlHttp.open("post", "https://www.carservicebooking.pl/new-test/send/");
        // console.log(JSON.stringify(form_data) + "asdas");
        if (this.countclick == 1) {
          xmlHttp.send(form_data);
          this.$gtag.event("Wysłanie formularza");
        }
      }
    },
    setDateForm(val) {
      if (val.split("-")[0] === "NaN") {
        this.formData.date = "";
        return;
      }
      this.formData.date = val;
    },
    setTimeOfDay(val) {
      this.formData.hour = val;
    },
    getImgUrl(car) {
      var images = require.context("../assets/images/", false, /\.png$/);
      return images("./" + car + ".png");
    },
    getModelImg(brand, model) {
      return this.imgError
        ? "@/assets/images/cars/" + brand.toLowerCase() + "-placeholder.png"
        : require("@/assets/images/cars/" +
            brand.toLowerCase() +
            "/" +
            model.toLowerCase().replace(/[^a-zA-Z0-9 ]/g, "") +
            ".png");
    },
    onImgError() {
      this.imgError = true;
    },
    setBrandId(id) {
      this.formData.brand_id = id;
      this.activeLogos = [];
    },
    setLoanCar(id, brand, model) {
      this.formData.loanCar = brand + " " + model + " Id: " + id;
    },
    resetDealer() {
      this.formData.dealer_id = "";
      this.formData.dealer_name = "";
    },
    selectItem(i) {
      this.isLogoActive = i;
    },
    selectServiceModal(i) {
      this.servicemodal = i;
    },
    selectLoanCar(i) {
      this.isLoanCar = i;
    },
    setDealerId(id, name, logosactive, brandid) {
      this.formData.dealer_id = id;
      this.formData.dealer_name = name;
      this.formData.brand_id = brandid;
      this.opened = false;
      this.isModalOpen = false;
      this.activeLogos = logosactive;
      this.fetchDates();
    },
    openLocModal() {
      var that = this;
      setTimeout(function() {
        that.isModalOpen = true;
      }, 1000);
      this.opened = true;
      this.$emit("opened", true);
    },
    // openModal() {
    // 	this.$refs.calendarinput.focus()
    // },
    hideLocModal() {
      if (this.isModalOpen === true) {
        this.opened = false;
        this.isModalOpen = false;
      }
    },
    isService(val) {
      if (this.formData.checkedServices.indexOf(val) !== -1) {
        this.reviewRole = false;
      } else {
        this.reviewRole = true;
      }
    },
    isOther(val) {
      if (this.formData.checkedServices.indexOf(val) !== -1) {
        this.otherRole = false;
      } else {
        this.otherRole = true;
      }
    },
    async fetchDealers() {
      await this.$store.dispatch("loadDealerList", this.formData.brand_id);
    },
    fetchDates() {
      this.$store.dispatch("loadDates", this.formData.dealer_id);
    },
    fetchCars() {
      this.$store.dispatch("loadCars", {
        dealer_id: this.formData.dealer_id,
        date: this.formData.date,
        hour: this.formData.hour,
      });
    },
  },
  mounted() {
    this.$store.dispatch("loadDealerList");
    if (this.$route.query.brand_id != undefined) {
      this.brandInUrl = true;
    }
    const input = document.getElementsByClassName("input__row");
    // const drop = document.getElementsByClassName("input__dropdown");
    // console.log("pusty? :", input[0]);
    document.getElementById("baner__menu").appendChild(input[0]);
    // document.getElementById("baner__menu").appendChild(drop[0]);
  },

  computed: {
    ...mapState(["dealerList", "dates", "cars"]),
    ...mapGetters(["getLoaderLoc", "getTempCity"]),
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/_variables.scss";

.checkbox__msg {
  border-radius: 10px;
}

small.extra_message {
  display: block;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: 700;
}

label.extraservices__content,
.services label,
.checkbox label {
  cursor: pointer;
}

.changeMileage {
  text-decoration: underline;
  margin-left: 15px;
}
.pointer {
  cursor: pointer;
}
.text-error {
  color: red;
  font-size: 11px;
}

.container {
  max-width: 1600px;
  margin: 0 auto;
  @media (max-width: 1700px) {
    max-width: 1540px;
  }
  @media (max-width: 1550px) {
    max-width: 1300px;
  }
  @media (max-width: 1310px) {
    margin: 0 10px;
  }
}
.relative {
  position: relative;
}
.form {
  margin-top: 3rem;
  margin-bottom: 55px;

  &__thanks {
    padding: 2rem 0;
  }

  &__row {
    display: flex;
    justify-content: space-between;
    transition: all 0.5s ease-in-out;

    @media (max-width: 960px) {
      display: block;
    }
  }

  &__col {
    &--header {
      font-size: 24px;
      font-weight: bold;
      color: $darkBlue;

      @media (max-width: 1500px) {
        font-size: 20px;
      }

      & br {
        @media (max-width: 960px) {
          display: none;
        }
      }
    }
    &--3 {
      width: calc(30% - 15px);
      padding: 40px 0;
      border-top: 3px solid $lightBlue;
      margin-right: 15px;

      @media (max-width: 960px) {
        width: 100%;
        margin: 0;
      }
    }
    &--7 {
      width: calc(70% - 15px);
      margin-left: 15px;
      border-top: 2px solid $darkBlue;
      padding: 40px 0;

      @media (max-width: 960px) {
        width: 100%;
        margin: 0;
      }
    }
    &--1 {
      width: calc(70% - 15px);
      margin-left: 15px;
      // border-top: 2px solid $darkBlue;
      padding: 40px 0;

      @media (max-width: 960px) {
        width: 100%;
        margin: 0;
      }
    }
  }
  &__wrap {
    margin-right: 20px;
    display: flex;

    &--reset {
      @media (min-width: 1150px) {
        margin-right: 0;
      }
    }

    @media (max-width: 550px) {
      margin-right: 0;
    }
  }
  &__label--white {
    font-size: 14px;
    color: white;
    font-weight: bold;
    margin-bottom: 5px;
  }
  &__label {
    font-size: 14px;
    color: #1b3354;
    font-weight: bold;
    margin-bottom: 5px;

    &--flex {
      display: flex;
    }
  }
  &__inputs {
    display: flex;

    @media (max-width: 1150px) {
      flex-wrap: wrap;
    }
    @media (max-width: 550px) {
      display: block;
    }
  }

  &__input {
    height: 22px;
    border-radius: 5px;
    padding: 0.5rem;
    outline: none;
    border: 1px solid $lightGrey;
    color: $black;
    width: 100%;
  }

  &__item {
    flex: 1;

    @media (max-width: 1150px) {
      flex: 1 0 50%;
      padding: 0.5rem 0;
    }
  }

  &__rodo {
    & p {
      padding: 1.5rem 0;
      color: $darkBlue;
    }
  }

  &__btn {
    width: 239px;
    border-radius: 10px;
    background-color: #3a98d8;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
    padding: 13px 0px;
    margin: 15px 0;
    border: 0px;
    cursor: pointer;

    &__wrap {
      text-align: center;
    }
    &:focus {
      outline: 0;
    }
  }
}
.blue {
  color: $lightBlue;
}
.logos {
  display: flex;
  justify-content: center;

  @media (max-width: 550px) {
    flex-wrap: wrap;
  }

  &__item {
    position: relative;

    @media (max-width: 550px) {
      flex: 1 0 50%;
      text-align: center;
    }
  }
  &__check {
    display: none;
    position: absolute;
    width: 20px;
    height: 20px;
    bottom: 10px;
    right: 5px;
    text-align: center;
    background-color: $lightBlue;

    &.active {
      display: block;
    }
  }
  &__tick {
    width: 70%;
  }
}
.logo {
  filter: grayscale(100%);
  transition: all 0.2s ease-in-out;
  cursor: pointer;

  &.active {
    filter: grayscale(0%);
    // border-bottom: 1px solid $lightBlue;
  }
  &:hover {
    filter: grayscale(0%);
  }
}
.input {
  &__mobileheader {
    color: white;
    padding-bottom: 0px;
    text-align: center;
    padding-bottom: 20px;
    border-bottom: 4px solid $lightBlue;
    span {
      font-weight: 300;
    }
    @media (max-width: 425px) {
      font-size: 1.6rem;
    }
  }
  &__disabled {
    display: inline-block;
    position: relative;
    width: 100%;
    .divwrap {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  &__header {
    color: white;
    padding-bottom: auto;
    padding-top: auto;
    border-bottom: 4px solid $lightBlue;
    padding-left: 20px;
    margin-bottom: 10px;
    @media (max-width: 425px) {
      font-size: 1.6rem;
    }
    @media (max-width: 1380px) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
    @media (min-width: 1480px) {
      padding-bottom: 20px;
      padding-top: 20px;
    }
  }
  &__calendar {
    // position: relative;
    input {
      font: normal normal 300 16px Roboto;
      color: black;
    }
  }
  &__flex {
    display: flex;
    input {
      font: normal normal 300 16px Roboto;
      color: black;
    }
  }
  &__block {
    position: absolute;
    top: 0;
    z-index: 999;
    background: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
  }
  &__row {
    // position: absolute;
    flex-direction: column;
    background: #1b3354 !important;
    // padding: 57px 65px;
    width: auto;
    min-width: 250px;
    height: 100%;
    // margin-bottom: 50px;
    z-index: 999;
    right: 0px;
    max-width: 538px;
    top: 0px;
    display: flex;
    justify-content: center;
    @media (max-width: 768px) {
      display: block;
    }
  }
  &__col {
    flex: 1;
    display: flex;
    align-items: center;
    @media (max-width: 768px) {
      margin: 1rem 0;
    }
  }
  &__item {
    flex: 2;
    margin-left: 15px;
    margin-right: 30px;
    cursor: pointer;
    z-index: 999;
    @media (max-width: 550px) {
      margin-right: 0;
    }
  }
  &__select {
    // height: 44px;
    // z-index: -1;
    margin: 0.5rem 0;
    border-radius: 5px;
    padding: 0.5rem;
    width: 100%;
    outline: none;
    font: normal normal 300 16px Roboto;
    border: 1px solid $lightGrey;
    // color: $lightGrey;
    background-color: white;
    color: lightgray;
    // background: none;
    @media (max-width: 399px) {
      margin-right: 20px;
    }
    &::placeholder {
      color: $lightGrey;
      // opacity: 0.5;
      color: lightgray;
    }
    option[value=""][disabled] {
      font: normal normal 300 16px Roboto !important;
      color: lightgray;
    }
    option {
      // font: normal normal 300 16px Roboto !important;
      font-family: Roboto;
      font-weight: 400;
      color: black;
    }
  }
}
.input__select.selected {
  font: normal normal bold 16px Roboto !important;
  color: black !important;
}
.row {
  &__wrap {
    margin: 0 7rem;

    @media (max-width: 1500px) {
      margin: 0 5rem;
    }
    @media (max-width: 960px) {
      margin: 0 0.2rem;
    }
  }
}
.text__center {
  text-align: center;
}
.services {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 2rem 0;

  &__overlay {
    position: absolute;
    bottom: 4px;
    height: 100%;
    width: 100%;
    background: rgb(0, 0, 0);
    background: linear-gradient(
      360deg,
      rgba(0, 0, 0, 0.5158438375350141) 8%,
      rgba(255, 255, 255, 0) 43%
    );
  }
  &__more--full {
    width: 100% !important;
    height: 100% !important;
  }
  &__more {
    position: absolute;
    // top: 350px;
    // left: 250px;
    width: 400px;
    // background-color: red;
    z-index: 9999;
    display: flex;
    &.hide {
      display: none;
    }
  }
  &__relative {
    position: relative;
  }
  &__absolute {
    position: absolute;
    bottom: 15px;
    width: 100%;
    z-index: 1;
  }
  &__link {
    color: $white;
    margin-left: 20px;
    font-weight: bold;
    cursor: pointer;
    &::after {
      content: "";
      background-image: url("../assets/images/elem/arrow-right.png");
      background-repeat: no-repeat;
      width: 16px;
      height: 16px;
      display: inline-block;
      position: relative;
      top: 2.5px;
      left: 4px;
    }
  }
  &__item {
    flex: 1 0 30%;
    margin-right: 15px;

    @media (max-width: 550px) {
      margin-right: 0;
    }

    & img {
      @media (max-width: 550px) {
        width: 100%;
      }
    }
  }

  &__text {
    margin: 2rem 0;
  }

  @media (max-width: 550px) {
    display: block;
  }
}
.radio {
  &__wrap {
    display: flex;
    padding: 0.2rem 0;
    input {
      height: 20px;
      width: 29px;
      margin: 0px 2px !important;
    }
  }
  &__label {
    color: #1b3354;
    margin-left: 10px;
    font: normal normal bold 16px Roboto;
  }
}
.extraservices {
  display: flex;
  justify-content: space-between;
  user-select: none;

  &__text {
    padding-left: 1.8rem;
    font-size: 12px;
  }
  &__content {
    padding: 1rem 1rem 2rem;
    border: 3px solid $lightGrey;
    border-radius: 20px;
    cursor: pointer;
    // flex: 1;
    flex: 0 1 46%;

    &:hover {
      border: 3px solid $lightBlue;
    }
    &.active {
      border: 3px solid $lightBlue;
    }

    &--margin {
      margin-right: 2rem;
      @media (max-width: 768px) {
        margin-right: 0;
      }
    }

    @media (max-width: 768px) {
      margin: 1rem 0;
      border: none !important;
    }
  }

  @media (max-width: 768px) {
    display: block;
  }
}
.extraservices-cars {
  &__flex {
    display: flex;
    justify-content: flex-end;
  }
  &__col {
    flex: 0 1 46%;
    padding: 1rem;
    @media (max-width: 768px) {
      flex: 0 1 100%;
      overflow-x: scroll;
    }

    p {
      @media (max-width: 350px) {
        font-size: 12px;
      }
    }
  }
  &__items {
    display: flex;
  }
  &__car {
    flex: 1;
    padding-right: 20px;
    padding-bottom: 20px;
    position: relative;
    transition: all 0.2s ease-in-out;
    cursor: pointer;

    &.active {
      border-bottom: 1px solid $lightBlue;
    }

    &:last-of-type {
      padding-right: 0;
    }
  }
  &__price {
    font-size: 1.2rem;
    color: $lightBlue;
    font-weight: 700;
  }
}
.checkbox {
  padding: 1rem 0 1.2rem;
  color: $darkBlue;
  font-weight: bold;

  &__title {
    margin-left: 10px;
  }
  &__msg {
    width: 100%;
    height: 120px;
    padding: 1rem;
    margin-bottom: 2rem;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  &__row {
    display: flex;
  }

  &__col {
    margin-right: 20px;
  }
}

.icon {
  &__wrap {
    & img {
      @media (max-width: 550px) {
        width: 60%;
      }
    }
    @media (max-width: 550px) {
      text-align: center;
    }
  }
}

.details {
  background-color: $darkBlue;
  color: $white;
  max-width: 530px;
  margin: 0 auto;

  &__position {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    z-index: 9999;
  }
  &__relative {
    position: relative;
    padding: 2rem;
  }
  &__absolute {
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    cursor: pointer;
  }
  &__text {
    font-weight: 700;
    font-size: 18px;

    @media (max-width: 550px) {
      font-size: 16px;
    }
  }
  &__shadow {
    -webkit-box-shadow: 0px 0px 28px 1px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 28px 1px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 28px 1px rgba(0, 0, 0, 0.75);
  }
}

input[type="checkbox"] {
  position: relative;
}
input[type="checkbox"]:after {
  content: " ";
  display: block;
  width: 1.1rem;
  height: 1.1rem;
  border-radius: 4px;
  position: absolute;
  top: -2px;
  left: -3px;
  background-color: #fff;
  text-align: center;
  border: 1px solid #aaa;
  cursor: pointer;
}
input[type="checkbox"]:checked:after {
  background: #3a98d8 url("../assets/images/elem/check.png") 50% 50% no-repeat;
  border: 1px solid #3a98d8;
  background-size: 50%;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.lds {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 16px;
  background: #3b99d9;
  animation: lds 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds div:nth-child(2) {
  left: 32px;
  animation-delay: -0.12s;
}
.lds div:nth-child(3) {
  left: 56px;
  animation-delay: 0;
}
@keyframes lds {
  0% {
    top: 8px;
    height: 64px;
  }
  50%,
  100% {
    top: 24px;
    height: 32px;
  }
}
.input__mobileheader {
  margin-top: 15px;
}
</style>
